<template>
  <div class="d-flex full-height" style="min-width: min-content">
    <div class="ant-gantt-items pos-rel ant-border-right">
      <panel-resizable
        class="d-flex flex-1 overflow-hidden ant-glass-background radius-0"
        side="left"
        :min-width="100"
        :default-width="400"
        :collapsible="false"
      >
        <div class="ant-border-bottom">
          <v-subheader class="px-6">
            <v-menu offset-y>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-icon class="mr-2" small v-on="{ ...tooltip, ...menu }"
                      >{{ headerStateIcon }}
                    </v-icon>
                  </template>
                  <span>Change calendar type</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item-group v-model="selectedHeader" color="primary">
                  <v-list-item v-for="(item, i) in headerOptions" :key="i">
                    <v-list-item-icon class="mr-2">
                      <v-icon small>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            Tasks
            <v-spacer />
            <slot name="ant-gantt-actions" />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  :color="showLabels ? 'primary' : ''"
                  v-bind="attrs"
                  @click="showLabels = !showLabels"
                  v-on="on"
                  >{{
                    showLabels
                      ? 'mdi-label-multiple'
                      : 'mdi-label-multiple-outline'
                  }}</v-icon
                >
              </template>
              <span>{{ showLabels ? 'Hide' : 'Show' }} labels</span>
            </v-tooltip>
          </v-subheader>
        </div>
        <div
          id="antGanttHeaders"
          ref="ant-gantt-headers"
          class="flex-1 d-flex flex-column overflow-hidden"
        >
          <slot name="task-header-items" />
        </div>
      </panel-resizable>
    </div>

    <div class="d-flex flex-column">
      <div
        class="overflow-x-auto d-flex ant-border-bottom ant-glass-background radius-0 overflow-y-hidden"
      >
        <div
          v-for="(count, index) in planningCount"
          :key="count"
          :style="{ width: `${getHeaderWidth(index)}px` }"
          class="d-flex align-center justify-center ant-border-right"
        >
          <v-subheader>
            {{
              planningStart.clone().add(index, headerState).format(headerFormat)
            }}
          </v-subheader>
        </div>
      </div>

      <div
        ref="ant-gantt-items"
        class="d-flex flex-column flex-1 ant-border-right overflow-y-auto overflow-x-hidden"
      >
        <div
          v-if="tasks.length === 0"
          style="top: 50px"
          class="d-flex align-center justify-center font-italic full-height"
        >
          No tasks found
        </div>
        <div class="pos-rel">
          <svg
            id="relations-container"
            class="pos-abs"
            style="left: 0; top: 0; z-index: 1"
            width="100%"
            height="100%"
          >
            <defs>
              <marker
                id="arrowhead"
                markerWidth="5"
                markerHeight="3.5"
                fill="#616161"
                refX="3"
                refY="1.75"
                orient="auto"
              >
                <polygon points="0 0, 5 1.75, 0 3.5" />
              </marker>
            </defs>
          </svg>
          <delete-dialog
            :dialog="displayRelationDeleteDialog"
            title="Are you sure you want to delete this relation"
            @closeDialog="closeTaskRelationDeleteDialog"
            @deleteAction="deleteTaskRelation"
          />
          <div
            class="pos-abs full-height"
            :style="{
              left: `${getTodayOffset(now)}px`,
              width: `${dayWidth}px`,
              'background-color': `white`,
            }"
          ></div>
          <div
            v-for="date in vacationDays"
            :key="date.id"
            class="pos-abs full-height"
            :style="{
              left: `${getTodayOffset(date.date)}px`,
              width: `${dayWidth}px`,
              'background-color': `lightgrey`,
            }"
          ></div>
          <div
            v-for="(task, index) in flattenedTasks"
            :id="`planning-${task.id}`"
            :key="task.id"
            class="d-flex align-center pos-rel"
            style="height: 36px; border-bottom: solid 1px lightgray"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="task.status === 'closed'"
                  style="position: absolute"
                  small
                  color="success"
                  :style="{
                    left: `${getTaskOffset(task) - 20}px`,
                  }"
                  >mdi-checkbox-marked-circle-outline
                </v-icon>
                <v-icon
                  v-if="task.status === 'canceled'"
                  style="position: absolute"
                  small
                  color="warning"
                  :style="{
                    left: `${getTaskOffset(task) - 20}px`,
                  }"
                  >mdi-alert-rhombus-outline
                </v-icon>
                <div
                  class="ant-gantt-task"
                  :style="{
                    transform: `translateX(${getTaskOffset(task)}px)`,
                    width: `${getTaskWidth(task)}px`,
                    background: `${getTaskColor(task)}`,
                    opacity: `${
                      highlightedItems.includes(task.id) ||
                      highlightedItems.length === 0
                        ? 1
                        : 0.5
                    }`,
                    border: `${
                      highlightedItems.includes(task.id)
                        ? 'dotted 3px white'
                        : ''
                    }`,
                  }"
                  v-on="on"
                >
                  <div
                    v-if="
                      (canLink &&
                        isLinking &&
                        link.from !== task &&
                        link.from.relations.findIndex(
                          (relation) => relation.task === task.id
                        ) === -1 &&
                        task.status !== 'closed') ||
                      highlightedRelation?.task === task.id
                    "
                    :class="{
                      'active-link': highlightedRelation?.task === task.id,
                    }"
                    class="link-task-option"
                    @click="linkTask(task)"
                  ></div>
                  <div
                    v-if="
                      (canLink &&
                        (!link.from || link.from === task) &&
                        task.status !== 'closed') ||
                      highlightedRelation?.previous_task === task.id
                    "
                    class="link-task-trigger"
                    :class="{
                      'active-link':
                        link.from === task ||
                        highlightedRelation?.previous_task === task.id,
                    }"
                    @click="setupLink(task)"
                  ></div>
                  <v-icon
                    v-if="task.hasChildren"
                    class="child-slack-icon"
                    :style="{ left: `${getMinSlack(task) - 5}px` }"
                  >
                    mdi-triangle-small-up
                  </v-icon>
                  <v-icon
                    v-if="task.hasChildren"
                    class="child-slack-icon"
                    :style="{ right: `${getMaxSlack(task) - 5}px` }"
                  >
                    mdi-triangle-small-up
                  </v-icon>
                </div>
              </template>
              <span>{{ getTaskPlanning(task) }}</span>
            </v-tooltip>
            <v-tooltip v-if="task.due" bottom>
              <template #activator="{ on, attrs }">
                <div
                  class="ant-gantt-due-date-label"
                  :style="{
                    transform: `translateX(${getTaskDueDateOffset(task)}px)`,
                    width: `${getDueWidth()}px`,
                  }"
                  v-on="on"
                ></div>
              </template>
              <span>Due: {{ getTaskDueDate(task) }}</span>
            </v-tooltip>
            <div
              v-if="isOverflowing(task)"
              class="overflow-overlay"
              :style="{
                transform: `translateX(${getTaskDueDateOffset(task)}px)`,
                width: `${getOverflowWidth(task)}px`,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DeleteDialog from '@/components/DeleteDialog';
import PanelResizable from '@/components/Project/PanelResizable';
import {
  getChartEndDate,
  getChartHeaderDayWidth,
  getChartHeaderFormat,
  getChartHeaderIcon,
  getChartHeaderWidth,
  getChartStartDate,
  getChartTaskColor,
  getChartTaskOffset,
  getChartTaskWidth,
} from '@/components/Charts/utils/tasks-chart.utils';

export default {
  name: 'TasksGanttChart',
  components: { PanelResizable, DeleteDialog },
  props: {
    highlightedItems: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    canLink: {
      type: Boolean,
      default: false,
    },
    vacationDays: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      dayWidth: 7,
      headerOptions: [
        {
          icon: 'mdi-calendar-month',
          text: 'month',
        },
        {
          icon: 'mdi-calendar-week',
          text: 'week',
        },
        {
          icon: 'mdi-calendar-today',
          text: 'day',
        },
      ],
      selectedHeader: 0,
      headerState: 'month',
      planningStart: null,
      planningEnd: null,
      showLabels: false,
      isLinking: false,
      displayRelationDeleteDialog: false,
      taskRelationDeleteId: null,
      link: {
        from: null,
        to: null,
      },
      highlightedRelation: null,
      now: moment(),
    };
  },
  computed: {
    headerStateIcon() {
      return getChartHeaderIcon(this.headerState);
    },
    headerFormat() {
      return getChartHeaderFormat(this.headerState);
    },
    planningCount() {
      return Math.ceil(
        this.planningEnd.diff(this.planningStart, `${this.headerState}s`, true)
      );
    },
    flattenedTasks() {
      return this.flattenTasks(this.tasks);
    },
  },
  watch: {
    tasks: {
      deep: true,
      immediate: true,
      handler(tasks) {
        this.clearRelations();
        this.planningStart = this.calculatePlanningStart();
        this.planningEnd = this.calculatePlanningEnd();
        this.drawRelations();
      },
    },
  },
  mounted() {
    this.$refs['ant-gantt-items'].addEventListener(
      'scroll',
      this.handleScrollEvent
    );
  },
  beforeDestroy() {
    this.$refs['ant-gantt-items'].removeEventListener(
      'scroll',
      this.handleScrollEvent
    );
  },
  methods: {
    isOverflowing(task) {
      return moment(task.due).isBefore(task.planned_end);
    },
    getOverflowWidth(task) {
      if (task.due && task.planned_end) {
        let hours = moment(task.planned_end).diff(moment(task.due), 'hours');
        return hours * (this.dayWidth / 24);
      } else {
        return 0;
      }
    },
    clearRelations() {
      let relationsContainer = document.getElementById('relations-container');
      if (relationsContainer) {
        relationsContainer.children.forEach((child) => {
          if (child.tagName === 'path') {
            child.remove();
          }
        });
      }
    },
    drawRelations() {
      this.$nextTick(() => {
        let relationsContainer = document.getElementById('relations-container');
        if (relationsContainer) {
          this.flattenedTasks.forEach((task, index) => {
            if (task?.relations?.length > 0) {
              task.relations.forEach((relation) => {
                let from = task;
                let to = this.flattenedTasks.find(
                  (tmp) => tmp.id === relation.task
                );

                if (to) {
                  let path = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'path'
                  );
                  let startX = Math.ceil(
                    this.getTaskOffset(from) + this.getTaskWidth(from)
                  );
                  let endX = Math.ceil(this.getTaskOffset(to));
                  let startY = index * 36 + 18;
                  let endY =
                    this.flattenedTasks.findIndex((x) => x.id === to?.id) * 36 +
                    18;

                  path.id = relation.id;
                  path.classList.add('task-relation');
                  path.setAttribute(
                    'd',
                    `M ${startX} ${startY} H ${startX + 10} V ${startY + 18} H ${
                      startX - 10
                    } V ${endY} H ${endX}`
                  );
                  path.setAttribute('stroke', '#616161');
                  path.setAttribute('fill', 'transparent');
                  path.setAttribute('stroke-width', '2');
                  path.setAttribute('marker-end', 'url(#arrowhead)');
                  path.style.zIndex = 2;
                  path.addEventListener(
                    'click',
                    this.removeLinkSetup.bind(null, relation)
                  );
                  path.addEventListener(
                    'mouseenter',
                    this.highlightRelation.bind(null, path, relation)
                  );
                  path.addEventListener(
                    'mouseleave',
                    this.removeHighlightRelation.bind(null, path)
                  );
                  relationsContainer.appendChild(path);
                }
              });
            }
          });

          this.$emit('scrollToToday');
        }
      });
    },
    highlightRelation(path, relation, event) {
      path.setAttribute('stroke', this.$vuetify.theme.themes.light.primary);
      path.style.zIndex = 4;
      this.highlightedRelation = relation;
    },
    removeHighlightRelation(path, event) {
      this.highlightedRelation = null;
      path.style.zIndex = 2;
      path.setAttribute('stroke', '#616161');
    },
    removeLinkSetup(relation, event) {
      this.displayRelationDeleteDialog = true;
      this.taskDeleteRelation = relation;
    },
    closeTaskRelationDeleteDialog() {
      this.displayRelationDeleteDialog = false;
      this.taskDeleteRelation = null;
    },
    deleteTaskRelation() {
      this.$store
        .dispatch('deleteTaskRelation', {
          taskId: this.taskDeleteRelation.previous_task,
          relationId: this.taskDeleteRelation.id,
        })
        .then(() => {
          this.clearRelations();
          this.drawRelations();
          this.closeTaskRelationDeleteDialog();
        });
    },
    setupLink(task) {
      this.isLinking = !this.isLinking;
      if (this.isLinking) {
        this.link.from = task;
      } else {
        this.link = {
          from: null,
          to: null,
        };
      }
    },
    linkTask(task) {
      this.link.to = task;
      this.$store
        .dispatch('createTaskRelation', {
          previousId: this.link.from.id,
          taskId: this.link.to.id,
        })
        .then(() => {
          this.link = {
            from: null,
            to: null,
          };
          this.isLinking = false;
        })
        .catch(() => {
          this.link.to = null;
        });
    },
    getDueWidth() {
      switch (this.headerState) {
        case 'month':
          return 3;
        case 'week':
          return 4;
        case 'day':
          return 5;
      }
    },
    handleScrollEvent() {
      this.$refs['ant-gantt-headers'].scrollTop =
        this.$refs['ant-gantt-items'].scrollTop;
    },
    flattenTasks(tasks) {
      let result = [];

      const flatten = (task) => {
        result.push(task);

        if (
          task.hasChildren &&
          !task.collapsed &&
          task.children &&
          task.children.length > 0
        ) {
          task.children.forEach((child) => {
            flatten(child);
          });
        }
      };

      tasks.forEach((task) => {
        flatten(task);
      });

      return result;
    },
    getMinSlack(task) {
      if (task.planned_start) {
        let hours = moment(task.childMinStart).diff(
          moment(task.planned_start),
          'hours'
        );
        return hours * (this.dayWidth / 24);
      } else {
        return 0;
      }
    },
    getMaxSlack(task) {
      if (task.planned_end) {
        let hours = moment(task.planned_end).diff(
          moment(task.childMaxEnd),
          'hours'
        );
        return hours * (this.dayWidth / 24);
      } else {
        return 0;
      }
    },
    getTaskPlanning(task) {
      return `${moment(task.planned_start).format(
        'YYYY-MM-DD HH:mm'
      )} / ${moment(task.planned_end).format('YYYY-MM-DD HH:mm')}`;
    },
    getTaskDueDateOffset(task) {
      let hours = moment(task.due).diff(this.planningStart, 'hours');
      return hours * (this.dayWidth / 24);
    },
    getTaskDueDate(task) {
      return moment(task.due).format('YYYY-MM-DD HH:mm');
    },
    calculatePlanningStart() {
      return getChartStartDate(this.flattenedTasks, this.headerState);
    },
    calculatePlanningEnd() {
      return getChartEndDate(this.flattenedTasks, this.headerState);
    },
    getHeaderWidth(index) {
      return getChartHeaderWidth(
        this.headerState,
        this.dayWidth,
        this.planningStart,
        index
      );
    },
    getTodayOffset(date) {
      let days = moment(date).diff(this.planningStart, 'days');
      return days * this.dayWidth;
    },
    getTaskColor(task) {
      return getChartTaskColor(task);
    },
    getTaskWidth(task) {
      return getChartTaskWidth(task, this.dayWidth);
    },
    getTaskOffset(task) {
      return getChartTaskOffset(task, this.dayWidth, this.planningStart);
    },
  },
};
</script>

<style scoped lang="scss">
.ant-gantt-items {
  display: flex;
  position: sticky;
  left: 0;
  justify-self: flex-start;
  background-color: #e6e9f0;
  z-index: 5;
}
.overflow-overlay {
  height: 30px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 2;
  background-color: var(--v-error-base);
  opacity: 0.6;
}

.ant-gantt-due-date-label {
  height: 100%;
  background-color: var(--v-primary-base);
  border: solid 1px white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  z-index: 3;
}

.ant-gantt-task {
  position: absolute;
  width: 5px;
  height: 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 3px;
  z-index: 2;

  .child-slack-icon {
    position: absolute;
    top: 24px;
    width: 10px;
    height: 10px;
  }

  .link-task-option {
    position: absolute;
    left: -5px;
    top: 7px;
    width: 10px;
    height: 10px;
    z-index: 4;
    background-color: white;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: 200ms ease;
    cursor: pointer;

    &:hover {
      background-color: var(--v-primary-base);
    }

    .active-link {
      transform: scale(1) !important;
      background-color: var(--v-primary-base) !important;
    }
  }

  .link-task-trigger {
    position: absolute;
    right: -5px;
    top: 7px;
    width: 10px;
    height: 10px;
    z-index: 4;
    background-color: white;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transform: scale(0);
    transition: 200ms ease;
    cursor: pointer;

    &:hover {
      background-color: var(--v-primary-base);
    }
  }

  .active-link {
    transform: scale(1) !important;
    background-color: var(--v-primary-base) !important;
  }

  &:hover {
    .link-task-trigger {
      transform: scale(1);
    }
  }
}

#antGanttHeaders::-webkit-scrollbar {
  display: none;
}
</style>
