<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    width="unset"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center">
        {{
          $t('system.tasks.deleteConfirm', {
            msg: $tc('system.tasks.deleteCountTasks', tasks.length),
          })
        }}
      </v-card-title>
      <template>
        <v-divider />
        <div class="px-4 py-2">
          <v-card-subtitle>{{
            $t('system.tasks.chooseMethod')
          }}</v-card-subtitle>
          <header class="tasks-header">
            <span class="tasks-header-text">
              {{ $t('system.tasks.single') }}
            </span>
            <span class="tasks-header-text">
              {{ $t('system.tasks.withSub') }}
            </span>
          </header>
          <v-card-text class="tasks-content">
            <div
              v-for="task in sortedTasks"
              :key="task.id"
              class="task-wrapper"
            >
              <router-link
                :to="`/tasks/${task.id}`"
                target="_blank"
                class="task-link"
              >
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div
                      :style="{ 'flex-grow': 1, 'word-break': 'break-word' }"
                      class="d-flex flex-column"
                      v-bind="attrs"
                      v-on="on"
                    >
                      #{{ task.number }} {{ task.title }}
                    </div>
                  </template>
                  <span>{{ $t('general.openInNewTab') }}</span>
                </v-tooltip>
              </router-link>
              <v-radio-group
                v-model="deleteParams[task.id]"
                :disabled="!task.hasChildren"
                row
              >
                <v-radio
                  v-for="method in availableDeleteMethods"
                  :key="method"
                  :value="method"
                />
              </v-radio-group>
            </div>
          </v-card-text>
        </div>
      </template>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          color="error"
          small
          text
          :disabled="$wait.is('tasks.delete')"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          small
          elevation="0"
          :loading="$wait.is('tasks.delete')"
          @click="deleteTasks"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  DELETE_METHOD_SINGLE,
  DELETE_METHOD_WITH_SUBTASKS,
} from '@/components/Tasks/task';

export default {
  name: 'DeleteTasksDialog',
  components: {},
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    initialDeleteMethod: {
      type: String,
      default: DELETE_METHOD_SINGLE,
    },
  },
  data: () => ({
    dialog: false,
    deleteParams: {},
    availableDeleteMethods: [DELETE_METHOD_SINGLE, DELETE_METHOD_WITH_SUBTASKS],
  }),
  computed: {
    sortedTasks() {
      const _tasks = this.tasks;
      return _tasks.sort((a, b) => (b.hasChildren || 0) - (a.hasChildren || 0));
    },
    initialDeleteParams() {
      return this.tasks?.reduce((acc, task) => {
        acc[task.id] = this.initialDeleteMethod;
        return acc;
      }, {});
    },
  },
  watch: {
    isShown: {
      handler() {
        this.dialog = this.isShown;
      },
      immediate: true,
    },
    tasks: {
      handler() {
        this.deleteParams = Object.assign({}, this.initialDeleteParams);
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.deleteParams = Object.assign({}, this.initialDeleteParams);
      this.$emit('close-dialog', (this.dialog = false));
    },
    deleteTasks() {
      const tasks = this.tasks.map((task) => {
        return {
          id: task.id,
          method: this.deleteParams[task.id],
        };
      });

      this.$store.dispatch('deleteTasks', { tasks }).then(() => {
        this.$store.commit('clear_selected_tasks');
        this.$emit('on-delete');
        this.closeDialog();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tasks-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 12px;

  .tasks-header-text {
    &:first-child {
      text-align: center;
      width: 26px;
      margin-right: 12px;
    }
  }

  &-text {
    font-size: 12px;
    font-weight: 500;
  }
}
.tasks-content {
  max-height: 400px;
  overflow-y: auto;
  padding: 0;

  .task-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 6px 12px;

    .task-link {
      display: inline-flex;
      flex-grow: 1;
      text-decoration: none;
      color: inherit;
      margin-right: 16px;
      max-width: 300px;

      &:hover {
        color: var(--v-primary-base);
      }
    }
  }

  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio {
    &:first-child {
      margin-right: 32px;
    }
  }
}
</style>
